.header {
  border-bottom: 1px solid var(--cstm-sky-20);
  height: 64px;
  background-color: var(--cstm-storm-light);
  position: sticky;
  top: 0;
  z-index: 9;

  &__box {
    display: flex;
    justify-content: space-between;
    height: inherit;
    align-items: center;

    font-family: "Roboto";
    font-size: 1.2rem;
    &__flex-left {
      &__path {
        display: flex;
        gap: 10px;
        padding-left: 20px;
        align-items: center;
        &__name-nav-item {
          display: flex;
          align-items: center;
        }
        &__step {
          display: flex;
          font-size: 15px;
          color: var(--cstm-storm-50);
          padding-top: 2px;
        }
      }
    }

    &__flex-right {
      display: flex;
      gap: 10px;
      padding-right: 1.25rem;
      align-items: center;

      &__btn {
        button {
          border: none;
          padding: 0.625rem 1.125rem;
          border-radius: 25px;
          font-family: "RobotoMed";
          background: var(--cstm-sky-key);
          color: #ffffff;
          cursor: pointer;
          font-size: 0.875rem;
        }
      }
      &__avatar {
        border: 1px solid #002247;
        position: relative;
        background: var(--cstm-sky-20);
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
      }

      &__userInfo {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 1rem;
        color: var(--cstm-storm-key);
        gap: 0.5rem;
        &__profile {
          display: flex;
          svg {
            width: 0.75rem;
            height: 0.75rem;
            path {
              stroke: var(--cstm-sky-key);
              stroke-width: 1.5px;
            }
          }
        }
        button {
          outline: none;
          border: none;
          background-color: transparent;
          padding: 0;
        }
      }
    }
  }
}
